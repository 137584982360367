import React, { useState } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import apiurl from '../apiUrl'
import './addNewsStyle.css';



const AddNews =()=>{

  const [selectedTitle, setSelectedTitle] = useState('');
const [dialogOpen, setDialogOpen] = useState(false);
const [showStateList, setShowStateList] = useState(false);
const [selectedState, setSelectedState] = useState('');
const [category, setCategory] = useState('');
const [type, setType] = useState('');
const [state, setState] = useState('');
const [options, setOptions] = useState([]);


    const [formData, setFormData] = useState({
        id: "",
        category:'',
        language: "",
        location:"",
        date: "",
        title: "",
        description: "",
        images: [], 
        link: "",
        details: [""],
        list: [{}], // Each row starts as an empty object
      });
    

      const handleImageChange = (e) => {
        const files = e.target.files;
        setFormData({ ...formData, images: files });
      };


      // Handle changes in main fields
      // const handleChange = (e, index, type = "details", keyName = "") => {
      //   const { name, value } = e.target;
    
      //   if (type === "details") {
      //     const updatedDetails = [...formData.details];
      //     updatedDetails[index] = value;
      //     setFormData({ ...formData, details: updatedDetails });
      //   } else if (type === "list") {
      //     const updatedList = [...formData.list];
      //     updatedList[index][keyName || name] = value; // Update specific key in the object
      //     setFormData({ ...formData, list: updatedList });
      //   } else {
      //     setFormData({ ...formData, [name]: value });
      //   }
      // };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      // Add a new field to a specific row in the list
      const addField = (rowIndex) => {
        const updatedList = [...formData.list];
        updatedList[rowIndex] = { ...updatedList[rowIndex], "": "" }; // Add a new key-value pair
        setFormData({ ...formData, list: updatedList });
      };
    
      // Add a new row to the list
      const addRow = () => {
        setFormData({
          ...formData,
          list: [...formData.list, {}], // Add a new empty object
        });
      };
    
      // Remove a specific row from the list
      const removeRow = (index) => {
        const updatedList = formData.list.filter((_, i) => i !== index);
        setFormData({ ...formData, list: updatedList });
      };
    
      // Remove a specific field from a row
      const removeField = (rowIndex, fieldKey) => {
        const updatedList = [...formData.list];
        delete updatedList[rowIndex][fieldKey]; // Delete the key-value pair
        setFormData({ ...formData, list: updatedList });
      };
    
      // Submit the form
        const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await addNews(formData); // Use the addNews function here
    if (response) {
      setFormData({
        id: "",
        category: "",
        language: "",
        date: "",
        title: "",
        description: "",
        images: [], // Reset images
        link: "",
        details: [""],
        list: [{}],
      }); // Reset the form data after submission
    }
  };

  // Function to handle submitting the news data
  const addNews = async (newsData) => {
    try {
      const form = new FormData();

      // Append the text fields
      form.append("id", newsData.id);
      form.append("category", newsData.category);
      form.append("language", newsData.language);
      form.append("location", newsData.location);
      form.append("date", newsData.date);
      form.append("title", newsData.title);
      form.append("description", newsData.description);
      form.append("link", newsData.link);
      form.append("list", JSON.stringify(newsData.list));

      // Append the image files
      for (let i = 0; i < newsData.images.length; i++) {
        form.append("images", newsData.images[i]);
      }

      // Append details and list if needed
      newsData.details.forEach((detail, index) => {
        form.append(`details[${index}]`, detail);
      });

      


      const token = localStorage.getItem("token");  // Retrieve the token from localStorage

    if (!token) {
      alert("Login required to perform this action.");
      return;  // Return early if no token is found
    }



    const response = await fetch(`${apiurl}/news/add`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`, // Attach the token to the request header
      },
      body: form,  // Send the form data (e.g., FormData object) as the request body
    });

    if (response.ok) {
      alert("News added successfully!");
      // Perform any other actions like refreshing the data
    } else {
      alert(`Failed to add news: ${response.statusText}`);
    }

      return await response.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  };
      // Function to handle submitting the news data
     
   

  

  

 


    


    return(
        <form className="addNF" onSubmit={handleSubmit} encType="multipart/form-data" >
        <h2 className="addNH2">Add or Edit News</h2>
        {/* Main Fields */}
        <label className="addNL">ID:</label>
        <input className="addNI"
          type="number"
          name="id"
          value={formData.id}
          onChange={(e) => handleChange(e)}
          
        />
        <label className="addNL">Category:</label>
            <select className="addNS"
            name="category"
            value={formData.category || ""}
            onChange={(e) => setFormData({ ...formData, category: e.target.value })}
            
            >
            <option value="" disabled>
                Select a category
            </option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            </select>

        <label className="addNL">Language:</label>
          <select className="addNS"
          name="language"
          value={formData.language || ""}
          onChange={(e) => setFormData({ ...formData, language: e.target.value })}
          
          >
          <option value="" disabled>
              Select a language
          </option>
          <option value="English">English</option>
          <option value="Hindi">Hindi</option>
          </select>

        <label className="addNL">Location:</label>
          <select className="addNS"
          name="location"
          value={formData.location || ""}
          onChange={(e) => setFormData({ ...formData, location: e.target.value })}
          
          >
          <option value="" disabled>
              Select a Location
          </option>
          <option value="center">Center</option>
          <option value="haryana">Haryana</option>
          <option value="u.p.">U.P.</option>
          <option value="bihar">Bihar</option>
          <option value="rajasthan">Rajasthan</option>
          </select>
  
          <label className="addNL">Date:</label>
          <input className="addNI"
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
          />

      <label className="addNL">Title:</label>
      <input className="addNI"
        type="text"
        name="title"
        value={formData.title}
        onChange={handleChange}
      />

      <label className="addNL">Description:</label>
      <textarea className="addNTA"
        name="description"
        value={formData.description}
        onChange={handleChange}
      />
{/* Multiple Image Selection */}
<label className="addNL">Images:</label>
      <input className="addNI"
        type="file"
        accept="image/*"
        multiple
        onChange={handleImageChange}
      />

      {/* Image Previews */}
      {formData.images.length > 0 && (
        <div>
          <p>Preview:</p>
          {Array.from(formData.images).map((file, index) => (
            <img
              key={index}
              src={URL.createObjectURL(file)} // Create a temporary URL for the preview
              alt={`Preview ${index}`}
              
            />
          ))}
        </div>
      )}
  
        <label className="addNL">Link:</label>
        <input className="addNI"
          type="url"
          name="link"
          value={formData.link}
          onChange={(e) => handleChange(e)}
        />
  
            {/* Dynamic Details */}
        <h3>Details</h3>
        {formData.details.map((detail, detailIndex) => (
          <div key={detailIndex} >
            <input className="addNI"
              type="text"
              value={detail}
              onChange={(e) => {
                const updatedDetails = [...formData.details];
                updatedDetails[detailIndex] = e.target.value;
                setFormData({ ...formData, details: updatedDetails });
              }}
              placeholder={`Detail ${detailIndex + 1}`}
              
            />
            <button className="addNB"
              type="button"
              onClick={() => {
                const updatedDetails = formData.details.filter((_, i) => i !== detailIndex);
                setFormData({ ...formData, details: updatedDetails });
              }}
            >
              Remove
            </button>
          </div>
        ))}
        <button className="addNB"
          type="button"
          onClick={() => {
            const updatedDetails = [...formData.details, ""];
            setFormData({ ...formData, details: updatedDetails });
          }}
          
        >
          Add Detail
        </button>
  
        {/* Dynamic List */}
        <h3>List</h3>
        {formData.list.map((row, rowIndex) => (
    <div
      key={rowIndex}
      
    >
      <h4>Row {rowIndex + 1}</h4>
      {Object.entries(row).map(([key, value], fieldIndex) => (
        <div
          key={fieldIndex}
         
        >
          <input className="addNI"
            type="text"
            value={key}
            onChange={(e) => {
              const newKey = e.target.value;
              const updatedList = [...formData.list];
              // Prevent empty key or duplicate key creation
              if (newKey.trim() !== "" && !updatedList[rowIndex][newKey]) {
                const currentValue = updatedList[rowIndex][key]; // Retain existing value
                delete updatedList[rowIndex][key]; // Remove old key
                updatedList[rowIndex][newKey] = currentValue; // Add new key-value pair
                setFormData({ ...formData, list: updatedList });
              }
            }}
            placeholder="Key"
            
          />
          <input className="addNI"
            type="text"
            value={value}
            onChange={(e) => {
              const updatedList = [...formData.list];
              updatedList[rowIndex][key] = e.target.value; // Update value
              setFormData({ ...formData, list: updatedList });
            }}
            placeholder="Value"
            
          />
          <button className="addNB"
            type="button"
            onClick={() => {
              const updatedList = [...formData.list];
              delete updatedList[rowIndex][key]; // Remove the field
              setFormData({ ...formData, list: updatedList });
            }}
          >
            Remove Field
          </button>
        </div>
      ))}
      <button className="addNB"
        type="button"
        onClick={() => {
          const updatedList = [...formData.list];
          updatedList[rowIndex][""] = ""; // Add a new key-value pair
          setFormData({ ...formData, list: updatedList });
        }}
      >
        Add Field
      </button>
      <button className="addNB"
        type="button"
        onClick={() => {
          const updatedList = formData.list.filter((_, i) => i !== rowIndex);
          setFormData({ ...formData, list: updatedList }); // Remove the row
        }}
      >
        Remove Row
      </button>
    </div>
  ))}
  
        <button className="addNB" type="button" onClick={addRow} >
          Add Row
        </button>
  
        <button className="addNB" type="submit" >
          Submit
        </button>
      </form>
    )
}

export default AddNews;