import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import apiurl from '../apiUrl.js';

import LoginSignup from "../Header/loginSignUp.js";
import TestListForm from "./AddNewExamForm.js";
import "./typeListStyle.css";

const ExamTypeList = () => {
  const { exam, examName } = useParams();
  const navigate = useNavigate();

  const [selectedTestType, setSelectedTestType] = useState("Full Test");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [testTypeData, setTestTypeData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropTypeClick = (testType) => {
    setSelectedTestType(testType);
    setDropdownOpen(false); // Close dropdown after selecting an item
  };

  const openDialogList = () => {setIsFormDialogOpen(true);
    setDropdownOpen(false);
  }
  const closeDialogList = () => setIsFormDialogOpen(false);

  

  const handleFormSubmit = async (formData) => {
    const token = localStorage.getItem("token");  // Retrieve the token from localStorage

if (token) {// Return early if no token is found
  try {
    const response = await axios.post(
      `${apiurl}/examlist/addexamtype`,
      { ...formData, exam, examName }, // Send the payload
      {
        headers: {
          Authorization: `Bearer ${token}`, // Include the Authorization header
        },
      }
    );

    if (response.status === 201) {
      alert("Exam type added successfully!"); // Success alert
      fetchTestData(); // Refresh the data
    } else {
      alert("Something went wrong. Please try again."); // Fallback in case of unexpected response
    }
  } catch (error) {
    alert(`Error: ${error.response?.data?.message || "Failed to create test"}`); // Error alert
    console.error("Error creating test:", error);
  }
}else{
  alert("Login required to perform this action.");

}
  };
  

  const fetchTestData = () => {
    if (exam && examName) {
      setIsLoading(true);
      axios
        .post(`${apiurl}/examlist/fetchexamlist`, { exam, examName })
        .then((response) => {
          
          setTestTypeData(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching test type data:", error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchTestData();
  }, [exam, examName]);

  const handleStartClick = (testId, testType) => {
    navigate(`/questionpage`, { state: { id: testId, exam, examName, testType } });
    const token = localStorage.getItem("token");
    if (!token) {
      setIsDialogOpen(true);
    }
  };

  const handleDeleteTestWithConfirmation = (testId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this test?"
    );
    if (isConfirmed) {
      handleDeleteTest(testId);
    }
  };

  const handleDeleteTest = async (testId) => {
    const token = localStorage.getItem("token"); // Retrieve the token from localStorage

      if (token) {
        
        try {
          const response = await axios.delete(
            `${apiurl}/examlist/deleteexamlist/${testId}`,
            { params: { exam, examName } }
          );
          
          fetchTestData(); // Refresh the data
        } catch (error) {
          console.error("Error deleting test:", error);
        }
      }else{
        alert("Login required to perform this action.");

      }
        };

    if (isLoading) {
      return <div className="loading-spinner">Loading...</div>;
    }

  const sortedTestTypeData = testTypeData
    .filter((test) => test.testType.startsWith(selectedTestType))
    .sort((a, b) => {
      const aTestNumber = parseInt(a.testType.split(" ")[2]);
      const bTestNumber = parseInt(b.testType.split(" ")[2]);
      return aTestNumber - bTestNumber;
    });

  return (
    <>
      <div className="selectBtn">
      <button
          className="dropdown-button"
          onClick={() => { setDropdownOpen(!dropdownOpen);}} >
          Select Test Type
        </button>

        <button onClick={openDialogList} className="addExamBtn">Add Exam Details</button>
      </div>

      {isFormDialogOpen && (
        <div className="dialogOverlay">
          <div className="dialogContent">
            <button className="closeButton" onClick={closeDialogList}>
              ✖
            </button>
            <TestListForm
              onSubmit={handleFormSubmit} onClose={closeDialogList}
              exam={exam}
              examName={examName}
            />
          </div>
        </div>
      )}


    
    {dropdownOpen && (
      <div className="dropdown-menu">
        {["Full Test", "Section Test", "Chapter Test", "Quiz", "Previous Year", "Notes"].map((testType) => (
          <div
            key={testType}
            className={`dropdown-item ${
              selectedTestType === testType ? "selected" : ""
            }`}
            onClick={() => handleDropTypeClick(testType)}
          >
            {testType}
          </div>
        ))}
      </div>
    )}



      <div className="testtypeheader">
        {[
          "Full Test",
          "Section Test",
          "Chapter Test",
          "Quiz",
          "Previous Year",
          "Notes",
        ].map((testType) => (
          <div
            key={testType}
            className={`testtype-item ${
              selectedTestType === testType ? "selected" : ""
            }`}
            onClick={() => setSelectedTestType(testType)}
          >
            {testType}
          </div>
        ))}
      </div>

      <div className="examTypeListSect">
        {sortedTestTypeData.length > 0 ? (
          sortedTestTypeData.map((test) => (
            <div key={test._id} className="examTypeListCard">
              <h3>{test.examName}</h3>
              <p>Serial Number: {test.serialNumber}</p>
              <span>Test Type: {test.testType}</span>
              <br />
              <span>Questions: {test.numberOfQuestions}</span>
              <br />
              <span>Marks: {test.marks}</span>
              <br />
              <span>Total Time: {test.time} minutes</span>
              <br />
              <span>Language(s): {test.language.join(", ")}</span>
              <br />
              <span>Syllabus: {test.syllabus}</span>
              <br />
              <button  onClick={() => handleStartClick(test._id, test.testType)} >
                Add Question </button>
              <button  onClick={() => handleDeleteTestWithConfirmation(test._id)}  >
                Delete Test  </button>
            </div>
          ))
        ) : (
          <p>No tests available for this test type.</p>
        )}
      </div>

      <LoginSignup
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default ExamTypeList;
