import React, { useState, } from 'react';
import { Dialog, DialogTitle, Tabs, Tab, TextField, Button, Avatar, Box } from '@mui/material';
import axios from 'axios';
import apiurl from '../apiUrl.js';


const LoginSignup = ({ open, onClose, onLogin }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [file, setFile] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [loginDetails, setLoginDetails] = useState({ email: '', password: '' });
  const [signupDetails, setSignupDetails] = useState({
    name: '',
    mobileNo: '',
    email: '',
    password: '',
    confirmPassword: '', admincode: ''
  });

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (tabIndex === 0) {
      setLoginDetails({ ...loginDetails, [name]: value });
    } else {
      setSignupDetails({ ...signupDetails, [name]: value });
    }
  };

  const handleSubmitLogin = async (event) => {
    event.preventDefault();
    try {
        const response = await axios.post(`${apiurl}/admin/login`, loginDetails);

        // Save the token and user information in localStorage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));

        alert('Login successful!'); // Show success message
        onLogin(); // Trigger login state in Navbar
        onClose(); // Close dialog
    } catch (error) {
        const errorMessage = error.response?.data?.message || 'An unknown error occurred. Please try again.';

        if (errorMessage === 'Email does not exist.') {
            alert('Email does not exist. Please check and try again.');
        } else if (errorMessage === 'Incorrect password.') {
            alert('Incorrect password. Please check and try again.');
        } else {
            alert('Login failed. Please check your internet connection and try again.');
        }

        console.error('Login failed:', errorMessage);
    }
};


  
  const handleImageUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setProfileImage(URL.createObjectURL(selectedFile)); // For preview
      setFile(selectedFile); // For actual file upload
    }
  };
  
  const handleSubmitSignup = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', signupDetails.name);
    formData.append('mobileNo', signupDetails.mobileNo);
    formData.append('email', signupDetails.email);
    formData.append('password', signupDetails.password);
    formData.append('admincode', signupDetails.admincode);
    if (profileImage) {
      formData.append('profileImage', file); // Correctly add the file as a File object
    }
  
    try {
      
      
      const response = await fetch(`${apiurl}/admin/signup`, {
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      
      
      if (response.ok) {
        alert('Signup successful! Now you can log in.');
        setTabIndex(0); // Switch to the login tab after signup
      } else {
        alert(data.message); // Display error message
      }
    } catch (error) {
      console.error('Error during signup:', error);
    }
  };
  


  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="Login" />
          <Tab label="Signup" />
        </Tabs>
      </DialogTitle>
      
      <Box p={3}>
        {tabIndex === 0 ? (
          <form onSubmit={handleSubmitLogin}>
            <TextField fullWidth label="Email" name="email" value={loginDetails.email} onChange={handleInputChange} variant="outlined" margin="normal" required/>
            <TextField fullWidth label="Password" name="password" type="text" value={loginDetails.password} onChange={handleInputChange} variant="outlined" margin="normal" required/>
            <Button fullWidth variant="contained" color="primary" type="submit">
              Login
            </Button>
          </form>
        ) : (
          <form onSubmit={handleSubmitSignup} encType="multipart/form-data">
           <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
<Avatar src={profileImage} sx={{ width: 80, height: 80, mb: 1 }} />
<Button variant="contained" component="label">
  Upload Profile Image
  <input type="file" hidden onChange={handleImageUpload} />
</Button>
</Box> 
            <TextField fullWidth label="Name" name="name" value={signupDetails.name} onChange={handleInputChange} variant="outlined" margin="normal" required/>
            <TextField fullWidth label="Mobile No" name="mobileNo" value={signupDetails.mobileNo} onChange={handleInputChange} variant="outlined" margin="normal" required/>
            <TextField fullWidth label="Email" name="email" value={signupDetails.email} onChange={handleInputChange} variant="outlined" margin="normal" required/>
            <TextField fullWidth label="Password" name="password" type="text" value={signupDetails.password} onChange={handleInputChange} variant="outlined" margin="normal" required/>
            <TextField fullWidth label="Confirm Password" name="confirmPassword" type="text" value={signupDetails.confirmPassword} onChange={handleInputChange} variant="outlined" margin="normal" required/>
            <TextField fullWidth label="Admin Code" name="admincode" value={signupDetails.admincode} onChange={handleInputChange} variant="outlined" margin="normal" required/>
            <Button fullWidth variant="contained" color="primary" type="submit">
              Signup
            </Button>
          </form>
        )}
      </Box>
    </Dialog>
  );
};

export default LoginSignup;
