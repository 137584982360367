import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import axios from "axios";
import "./addDetailsS.css";
import apiurl from "../apiUrl";

const catList = ["Previous Exam", "Guess Paper", "Entrance Exam", "Practical/Asses.", "Study Material"];

const examNames = [
  { name: "B.A", courseTypes: ["History", "English", "Economics"] },
  { name: "B.Sc", courseTypes: ["Physics", "Chemistry", "Biology"] },
  { name: "B.Com", courseTypes: ["Accounting", "Finance", "Marketing"] },
];
const semesters = {
  "B.A": ["1st", "2nd", "3rd", "All"],
  "B.Sc": ["1st", "2nd", "3rd", "4th", "All"],
  "B.Com": ["1st", "2nd", "3rd", "4th", "5th", "6th", "All"]
};


// const years = Array.from({ length: 8 }, (_, i) => (2017 + i).toString());
const years = ["2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025" ]


const AddUniDetail = () => {
  const { university } = useParams();
  const [activeSection, setActiveSection] = useState("upload");
  const [modifySearch, setModifySearch] = useState({
    category:"",
    courseType:"",
    examName: "",
    semester: "",
  });
  
  const [formData, setFormData] = useState({
    category :"",
    program: "",
    examName: "",
    courseType: "",
    semester: "",
    year: "",
    subjectName: "",
    file: null,
  });
  const [searchResults, setSearchResults] = useState([]);

  // Handles form data changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
      ...(name === "examName" && { courseType: "" }),
      // ...(name === "year" && { semester: "" }),
    }));
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!university) {
      alert("University name is missing in the URL.");
      return;
    }

    const uploadData = new FormData();
    
    uploadData.append("university", university);
    uploadData.append('category', formData.category);
    uploadData.append('program', formData.program);
    uploadData.append("examName", formData.examName);
    uploadData.append("courseType", formData.courseType);
    uploadData.append("semester", formData.semester);
    uploadData.append("year", formData.year);
    uploadData.append("subjectName", formData.subjectName);
    uploadData.append("file", formData.file);

    try {
      // Retrieve token from localStorage
      for (let [key, value] of uploadData.entries()) {
        console.log(`${key}: ${value}`);}
      const token = localStorage.getItem("token");
      console.log(token);
    
      // Check if the token is present
      if (token) {
        const response = await axios.post(
          `${apiurl}/unidata/addexamfile`, 
          uploadData, 
          {
            headers: {
              Authorization: `Bearer ${token}`, // Attach token to the request header
            },
          }
        );
        if (response.status === 201) {
          alert("File uploaded successfully!");
        } else if (response.status === 409) {
          alert("This file already exists.");
        } else {
          alert(`Unexpected response: ${response.data.message || "No message available"}`);
        }
        
      }else{
        alert("You must be logged in to Add a file.");
        
      }
      
      
      // Handle different response status codes
      
    } catch (error) {
      // Handle errors with specific checks for error status
      if (error.response) {
        if (error.response.status === 409) {
          alert("This file already exists.");
        } else {
          alert(`Unexpected error: ${error.response.data.message || "No message available"}`);
        }
      } else {
        console.error("Error uploading file:", error); // Log detailed error information
        alert("An error occurred while uploading the file.");
      }
    }
    
  };

  const handleModifySearch = async () => {
    try {
        const response = await axios.get(`${apiurl}/unidata/modifyfile`, {
            params: { ...modifySearch, university },
        });

        // Log the entire response for debugging
        console.log("Modify Search Response:", response.data);

        // Extract 'files' from the response or fallback to an empty array
        const files = response.data?.files || [];

        // If no files are returned, show an alert or handle gracefully
        if (files.length === 0) {
            alert(response.data.message || "No files found for the given search criteria.");
        } else {
            setSearchResults(files);
        }
    } catch (error) {
      console.error("Error fetching data:", error);
        alert("An error occurred while fetching search results.");
    }
};


const handleDownload = async (filename) => {
  try {
    console.log(`Downloading: ${filename}`);
    const response = await fetch(`${apiurl}/unidata/download/${filename}`, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(`Failed to download file: ${response.statusText}`);
    }

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    // Create a temporary link to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    // Clean up resources
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    console.log(`${filename} downloaded successfully!`);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

const selectedExam = examNames.find((exam) => exam.name === formData.examName);

  
  

  const handleDelete = async (fileId) => {
    try {
      // Retrieve the token from localStorage or wherever it's stored
      const token = localStorage.getItem("token"); 
  
      // Check if the token is present
      if (token) {
        const response = await axios.delete(
          `${apiurl}/unidata/remove/${university}/${fileId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Attach token to the request header
            },
          }
        );
        
        if (response.status === 200) {
          alert("File deleted successfully!");
          setSearchResults((prevResults) => prevResults.filter((file) => file.id !== fileId));
        }
      }else{
        
        alert("You must be logged in to delete a file.");
      }
  
      // Send the DELETE request with the Authorization header
  
    } catch (error) {
      console.error("Error deleting file:", error);
      alert("An error occurred while deleting the file.");
    }
  };

  const toUniverName = (str)=>{
    return str
    .split(' ') // Split string into words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
    .join(' '); // Join words back into a string
  }
  
 

  return (
    <div>
      <h1>{toUniverName(university.replaceAll('_', ' '))} Exam Manage</h1>

      {/* Header Section */}
      <div className="header-tabs">
        <button
          className={activeSection === "upload" ? "active" : ""}
          onClick={() => setActiveSection("upload")}
        >
          Upload
        </button>
        <button
          className={activeSection === "modify" ? "active" : ""}
          onClick={() => setActiveSection("modify")}
        >
          Modify
        </button>
      </div>

      {/* Conditional Rendering */}
      {
     activeSection === "upload" && (
      <section>
        <h2>Upload Exam File</h2>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div>
            <label>Select Category:</label>
            <select
              name="category"
              value={formData.category}
              onChange={handleChange}
              required
            >
              <option value="">Select Category Type</option>
              {catList.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label>Select Program:</label>
            <select
              name="program"
              value={formData.program}
              onChange={handleChange}
              required
              disabled={!formData.category}
            >
              <option value="">Select Program Type</option>
              <option value="bachelor">Bachelor</option>
              <option value="master">Master</option>
            </select>
          </div>

          <div>
            <label>Exam Name:</label>
            <select
              name="examName"
              value={formData.examName}
              onChange={handleChange}
              required
              disabled={!formData.program}
            >
              <option value="">Select Exam Name</option>
              {examNames.map((exam, index) => (
                <option key={index} value={exam.name}>
                  {exam.name}
                </option>
              ))}
            </select>
          </div>

          {formData.examName && (
            <div>
              <label>Course Type:</label>
              <select
                name="courseType"
                value={formData.courseType}
                onChange={handleChange}
                required
                disabled={!formData.examName}
              >
                <option value="">Select Course Type</option>
                {selectedExam?.courseTypes.map((course, index) => (
                  <option key={index} value={course}>
                    {course}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div>
            <label>Semester:</label>
            <select
              name="semester"
              value={formData.semester}
              onChange={handleChange}
              required
              disabled={!formData.courseType}
            >
              <option value="">Select Semester</option>
              {formData.examName &&
                semesters[formData.examName].map((sem, index) => (
                  <option key={index} value={sem}>
                    {sem}
                  </option>
                ))}
            </select>
          </div>

          <div>
            <label>Year:</label>
            <select
              name="year"
              value={formData.year}
              onChange={handleChange}
              required
              disabled={!formData.semester}
            >
              <option value="">Select Year</option>
              {years.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label>Subject Name:</label>
            <input
              type="text"
              name="subjectName"
              value={formData.subjectName}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label>File:</label>
            <input
              type="file"
              name="file"
              accept=".pdf,.docx"
              onChange={handleChange}
              required
            />
          </div>

          <button type="submit">Upload</button>
        </form>
      </section>
    )
      
      }

{activeSection === "modify" && (
  <section>
    <h2>Modify Exam Files</h2>

      {/* Category Dropdown */}
      <div>
      <label>Category Type:</label>
      <select
        value={modifySearch.category}
        onChange={(e) => setModifySearch({ ...modifySearch, category: e.target.value })}
      >
        <option value="">Select Category Type</option>
        {catList.map((type, index) => (
          <option key={index} value={type}>{type}</option>
        ))}
      </select>
    </div>

    {/* Exam Name Dropdown */}
    <div>
      <label>Exam Name:</label>
      <select
        value={modifySearch.examName}
        onChange={(e) => setModifySearch({ ...modifySearch, examName: e.target.value, courseType: "", semester: "" })}
      >
        <option value="">Select Exam Name</option>
        {examNames.map((exam, index) => (
          <option key={index} value={exam.name}>{exam.name}</option>
        ))}
      </select>
    </div>

  

    {/* Course Type Dropdown */}
    <div>
      <label>Course Type:</label>
      <select
        value={modifySearch.courseType}
        onChange={(e) => setModifySearch({ ...modifySearch, courseType: e.target.value })}
        disabled={!modifySearch.examName}
      >
        <option value="">Select Course Type</option>
        {modifySearch.examName &&
          examNames
            .find((exam) => exam.name === modifySearch.examName)
            ?.courseTypes.map((type, index) => (
              <option key={index} value={type}>{type}</option>
            ))}
      </select>
    </div>

    {/* Semester Dropdown */}
    <div>
      <label>Semester:</label>
      <select
        value={modifySearch.semester}
        onChange={(e) => setModifySearch({ ...modifySearch, semester: e.target.value })}
        disabled={!modifySearch.examName}
      >
        <option value="">Select Semester</option>
        {modifySearch.examName &&
          semesters[modifySearch.examName]?.map((sem, index) => (
            <option key={index} value={sem}>{sem}</option>
          ))}
      </select>
    </div>

    {/* Search Button */}
    <button onClick={handleModifySearch}>Search</button>

    {/* Search Results */}
    {searchResults.length > 0 ? (
      searchResults.map((file, index) => (
        <div key={file._id} className="file-card">
          <div className="card-header">
            <span>
              <strong>S.No:</strong> {index + 1} {file.examName} {file.courseType}
            </span>
          </div>

          <div className="card-body">
            <span>
              {file.subjectName || ""} {file.semester} sem. {file.year}
            </span>
          </div>

          <div className="card-actions">
            <button
              className="downloadBtn"
              onClick={() => handleDownload(file.fileName)}
            >
              Download
            </button>
            <button
              className="deleteBtn"
              onClick={() => {
                if (window.confirm("Are you sure you want to delete this file?")) {
                  handleDelete(file._id); // Trigger delete on confirmation
                }
              }}
            >
              Delete
            </button>
          </div>
        </div>
      ))
    ) : (
      <p>No files found. Please refine your search criteria.</p>
    )}
  </section>
)}


    </div>
  );
};

export default AddUniDetail;
